import React, { useReducer } from "react";
import CompanyContext from "./companyContext";
import CompanyReducer from "./companyReducer";
import api from "../../api/api";
import { GET_COMPANIES, SET_ERROR, CLEAR_STATE, SET_LOADING } from "../types";

const CompanyState = (props) => {
  const initialState = {
    companies: [],
    success: null,
    loading: false,
    error: null,
  };

  const [state, dispatch] = useReducer(CompanyReducer, initialState);

  //Get Companies
  const getCompanies = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Client-Name": "carone",
      },
    };
    setLoading();
    try {
      const res = await api.get(
        `/companies?sort=name?group=61003180d1cd3b1299a82fd0`,
        config
      );
      dispatch({ type: GET_COMPANIES, payload: res.data.data });
    } catch (err) {
      if (err.response)
        dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Clear State
  const clearState = () => dispatch({ type: CLEAR_STATE });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <CompanyContext.Provider
      value={{
        loading: state.loading,
        companies: state.companies,
        error: state.error,
        success: state.success,
        getCompanies,
        clearState,
        setLoading,
      }}
    >
      {props.children}
    </CompanyContext.Provider>
  );
};

export default CompanyState;
