import { GET_STORES, SET_ERROR, CLEAR_STATE, SET_LOADING } from "../types";
import { DEFAULT_STATE } from "../../constants/stateStore";

const reducer = (state, action) => {
  switch (action.type) {
    case GET_STORES:
      return {
        ...state,
        stores: action.payload,
        count: action.count,
        loading: false,
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case CLEAR_STATE:
      return DEFAULT_STATE;
    case SET_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return { ...state };
  }
};

export default reducer;
