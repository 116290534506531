// General
export const CLEAR_STATE = "CLEAR_STATE";
export const SET_ERROR = "SET_ERROR";
export const SET_LOADING = "SET_LOADING";
export const CLEAR_SUCCESS = "CLEAR_SUCCESS";

// Auth
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const UPDATE_PROFILE = "UPDATE_PROFILE";

// Companies
export const GET_COMPANIES = "GET_COMPANIES";

// Stores
export const GET_STORES = "GET_STORES";

// Stores
export const GET_SOURCES = "GET_SOURCES";

// Vehicles
export const GET_VEHICLES = "GET_VEHICLES";

// Leads
export const CREATE_LEAD = "CREATE_LEAD";

// Alert
export const REMOVE_ALERT = "REMOVE_ALERT";
export const SET_ALERT = "SET_ALERT";
export const SET_ALERTS = "SET_ALERTS";
