import { generateYears } from "../../utils/generatedYears";

export const TIME_FRAMES = [
  { value: 0, label: "Solo quiero información" },
  { value: 1, label: "1 Mes o Menos" },
  { value: 2, label: "2 Meses" },
  { value: 3, label: "3 Meses o Más" },
];

const YEARS_TO_GENERATE = 5;

export const YEARS = generateYears(YEARS_TO_GENERATE);

export const TAKE_CAR_IN_TRADES = [
  { value: true, label: "Si" },
  { value: false, label: "No" },
];

export const TYPE_LEAD_SHOWROOM = "showroom";

export const FROM_LEAD_QUEST = "quest";
