import React, { useReducer } from "react";
import VehicleContext from "./vehicleContext";
import VehicleReducer from "./vehicleReducer";
import api from "../../api/api";
import { GET_VEHICLES, SET_ERROR, SET_LOADING, CLEAR_STATE } from "../types";

const VehicleState = (props) => {
  const initialState = {
    vehicles: [],
    loading: false,
    error: null,
    success: null,
    count: null,
  };

  const [state, dispatch] = useReducer(VehicleReducer, initialState);

  //Get Vehicles
  const getVehicles = async (query) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Client-Name": "carone",
      },
    };
    setLoading();
    try {
      const res = await api.post(`/vehicles/searchDocuments`, query, config);
      dispatch({
        type: GET_VEHICLES,
        payload: res.data.results,
        count: res.data.pagination.total,
      });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Clear State
  const clearState = () => dispatch({ type: CLEAR_STATE });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <VehicleContext.Provider
      value={{
        loading: state.loading,
        vehicles: state.vehicles,
        error: state.error,
        success: state.success,
        count: state.count,
        getVehicles,
        clearState,
        setLoading,
      }}
    >
      {props.children}
    </VehicleContext.Provider>
  );
};

export default VehicleState;
