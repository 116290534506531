import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Formik } from "formik";
import Alert from "../../components/Alert";
import useAuth from "../../hooks/useAuth";
import useAlert from "../../hooks/useAlert";
import { Eye, EyeOff } from "react-feather";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  FormHelperText,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
} from "@mui/material";

const JWTLogin = ({ className, ...rest }) => {
  const { login, error, loadUser, success, clearSuccess } = useAuth();
  const { setAlert, alert } = useAlert();
  const [show, setShow] = useState("password");
  const navigate = useNavigate();

  const handleClickShowPassword = () => {
    if (show === "password") {
      setShow("text");
    } else {
      setShow("password");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      loadUser();
      navigate("/form");
    }
  }, []);

  useEffect(() => {
    if (error) setAlert({ message: error, type: "error" });
    if (success) navigate("/form");
  }, [error, success]);

  useEffect(() => {
    return () => clearSuccess();
  }, []);

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        submit: null,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("This is not a valid email")
          .max(255)
          .required("Email required!"),
        password: Yup.string().max(255).required("Password is required"),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          login(values);
        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: err.error });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form
          noValidate
          onSubmit={handleSubmit}
          className={className}
          {...rest}
        >
          <Box>
            <Typography color="textSecondary" variant="caption">
              Correo Electrónico
            </Typography>
            <TextField
              error={Boolean(touched.email && errors.email)}
              fullWidth
              autoFocus
              helperText={touched.email && errors.email}
              margin="dense"
              name="email"
              onBlur={handleBlur}
              onChange={handleChange}
              type="email"
              value={values.email}
              variant="outlined"
            />
          </Box>
          <Box pt={2}>
            <Typography color="textSecondary" variant="caption">
              Contraseña
            </Typography>
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              margin="dense"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type={show}
              value={values.password}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {show === "password" ? <Eye /> : <EyeOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          {alert && alert.message && (
            <Box>
              <Alert
                titleProps={{ style: { margin: 0, padding: 0 } }}
                messageProps={{ style: { margin: 0, padding: 0 } }}
                severity={alert.type}
                message={alert.message}
              />
            </Box>
          )}

          {errors.submit && (
            <Box mt={3}>
              <FormHelperText error>{errors.submit}</FormHelperText>
            </Box>
          )}
          <Box mt={4} display="flex" justifyContent={"center"}>
            <Button
              color="primary"
              disabled={isSubmitting}
              size="large"
              fullWidth
              type="submit"
              variant="contained"
              style={{
                border: "none",
                boxShadow: "none",
                color: "white",
                height: 50,
              }}
            >
              Login
            </Button>
          </Box>
        </form>
      )}
    </Formik>
  );
};

JWTLogin.propTypes = {
  className: PropTypes.string,
};

export default JWTLogin;
