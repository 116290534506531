import React, { useReducer } from "react";
import LeadContext from "./leadContext";
import LeadReducer from "./leadReducer";
import api from "../../api/api";
import { CREATE_LEAD, SET_ERROR, CLEAR_STATE, SET_LOADING } from "../types";

const LeadState = (props) => {
  const initialState = {
    lead: {},
    loading: false,
    error: null,
    success: null,
  };

  const [state, dispatch] = useReducer(LeadReducer, initialState);

  //Create Lead
  const createLead = async (lead) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Client-Name": "carone",
      },
    };
    clearState();
    setLoading();
    try {
      const res = await api.post(`/leads`, { ...lead }, config);
      dispatch({ type: CREATE_LEAD, payload: res.data.data });
    } catch (err) {
      if (err.response)
        dispatch({ type: SET_ERROR, payload: err.response.data.error });
    }
  };

  //Clear State
  const clearState = () => dispatch({ type: CLEAR_STATE });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <LeadContext.Provider
      value={{
        loading: state.loading,
        lead: state.lead,
        error: state.error,
        success: state.success,
        createLead,
        clearState,
        setLoading,
      }}
    >
      {props.children}
    </LeadContext.Provider>
  );
};

export default LeadState;
