import {
  GET_COMPANIES,
  SET_ERROR,
  CLEAR_STATE,
  SET_LOADING
} from '../types';

const reducer = (state, action) => {
  switch (action.type) {
    case GET_COMPANIES:
      return {
        ...state,
        companies: action.payload,
        loading: false,
        error: null,
        success: 'Compañías Cargadas'
      };
    case SET_ERROR:
      return {
        ...state,
        success: null,
        error: action.payload,
        loading: false
      };
    case CLEAR_STATE:
      return {
        companies: [],
        loading: false,
        success: null,
        error: null
      };
    case SET_LOADING:
      return {
        ...state,
        loading: true
      };
    default:
      return { ...state };
  }
};

export default reducer;
