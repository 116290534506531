import React, { useReducer } from "react";
import SourceContext from "./sourceContext";
import SourceReducer from "./sourceReducer";
import api from "../../api/api";
import { GET_SOURCES, SET_ERROR, CLEAR_STATE, SET_LOADING } from "../types";

const SourceState = (props) => {
  const initialState = {
    sources: [],
    loading: false,
    error: null,
    success: null,
  };

  const [state, dispatch] = useReducer(SourceReducer, initialState);

  //Get Sources
  const getSources = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Client-Name": "carone",
      },
    };
    setLoading();
    try {
      const res = await api.get(`/sources/isActives`, config);
      dispatch({ type: GET_SOURCES, payload: res.data.data });
    } catch (err) {
      dispatch({ type: SET_ERROR, payload: err.response.data });
    }
  };

  //Clear State
  const clearSourceState = () => dispatch({ type: CLEAR_STATE });

  //Set Loading
  const setLoading = () => dispatch({ type: SET_LOADING });

  return (
    <SourceContext.Provider
      value={{
        loading: state.loading,
        sources: state.sources,
        error: state.error,
        success: state.success,
        getSources,
        clearSourceState,
        setLoading,
      }}
    >
      {props.children}
    </SourceContext.Provider>
  );
};

export default SourceState;
