import React from 'react';
import PropTypes from 'prop-types';
import { Container } from '@mui/material';
import { Outlet } from 'react-router-dom';

const MainLayout = () => {

  const styles = {
    wrapper: {
      backgroundColor: '#E6E6EC',
      backgroundImage: 'url("/static/backgroundLogin.jpg")',
      backgroundSize: '100%',
      backgroundPosition: 'center',
      display: 'flex',
      minHeight: '100vh',
      overflow: 'hidden',
      paddingTop: '1%',
    },
  }

  return (
    <div style={styles.wrapper}>
      <Container maxWidth="lg">
        <Outlet />
      </Container>
  </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node
};

export default MainLayout;
