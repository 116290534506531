import { 
  GET_SOURCES, 
  SET_ERROR,
  CLEAR_STATE,
  SET_LOADING
 } from '../types';

const reducer = (state, action) => {
  switch (action.type) {
    case GET_SOURCES:
      return {
        ...state,
        sources: action.payload,
        loading: false, 
        error: null,
        success: 'Fuentes Cargadas'
      };
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
        loading: false
      }
    case CLEAR_STATE:
      return {
        sources: [],
        loading: false,
        error: null,
        success: null
      }
    case SET_LOADING:
      return {
        ...state,
        loading: true
        }
    default:
      return { ...state };
  }
};

export default reducer;