const SOURCES = [
  { id: "605b5438020c150355aac5e8", name: "Facebook" },
  { id: "645444f56d9ee3322e60584b", name: "Call Center" },
  { id: "645448217e3a2331d465f15f", name: "Cliente Anterior" },
  { id: "64544512a1891e324b56ef1e", name: "Eventos" },
  { id: "612fddbf47d987b47bab11e9", name: "Mailing" },
  { id: "6454453c8d5b873228c5271a", name: "Internet Planta" },
  { id: "645445707e3a2331d465ddce", name: "Internet Car One" },
  { id: "6454454ba1891e324b56f150", name: "Panoramico" },
  { id: "62704ca09081bf61bb5a0309", name: "App Car One" },
  { id: "6454455a8638e7320c758326", name: "Prensa" },
  { id: "6454457d8d5b873228c528fc", name: "Radio" },
  { id: "645445872d2bd131dbd3cd93", name: "Referencia" },
  { id: "645445938d5b873228c5298c", name: "Uber" },
  { id: "645445a18d5b873228c52a09", name: "Televisión" },
  { id: "645445b0cc342132135e2a7e", name: "Volante" },
  { id: "645445c08638e7320c758570", name: "Citas Redes Sociales" },
  { id: "645445d3cc342132135e2b60", name: "Flotillas" },
  { id: "645445df8638e7320c758685", name: "Punto de venta" },
  { id: "6454461ccc342132135e2d9d", name: "Instagram" },
  { id: "645446138d5b873228c52d5c", name: "Google" },
];

export default SOURCES;
