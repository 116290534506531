import { SET_ALERT, SET_ALERTS, REMOVE_ALERT, CLEAR_STATE } from "../types";

const reducer = (state, action) => {
  switch (action.type) {
    case SET_ALERT:
      return {
        ...state,
        alert: action.payload,
      };
    case SET_ALERTS:
      return {
        ...state,
        alerts: action.payload,
      };
    case CLEAR_STATE:
      return {
        ...state,
        alert: {
          message: null,
          type: null,
          title: null,
        },
        alerts: [],
      };
    case REMOVE_ALERT:
      return {
        alert: {
          message: null,
          type: null,
          title: null,
        },
        alerts: [],
      };
    default:
      return state;
  }
};

export default reducer;
