import React, { useReducer } from "react";
import AlertContext from "./alertContext";
import AlertReducer from "./alertReducer";
import { CLEAR_STATE, REMOVE_ALERT, SET_ALERT, SET_ALERTS } from "../types";

const AlertState = (props) => {
  const initialState = {
    alert: {
      message: null,
      type: null,
      title: null,
    },
    alerts: [],
  };

  const [state, dispatch] = useReducer(AlertReducer, initialState);

  const clearState = () => dispatch({ type: CLEAR_STATE });

  const setAlert = async (value, remove = true) => {
    clearState();
    dispatch({ type: SET_ALERT, payload: value });
    if (remove) removeAlert();
  };

  const setAlerts = async (value, remove = true) => {
    clearState();
    dispatch({ type: SET_ALERTS, payload: value });
    if (remove) removeAlert();
  };

  const removeAlert = () =>
    setTimeout(() => dispatch({ type: REMOVE_ALERT }), 3000);

  return (
    <AlertContext.Provider
      value={{
        alert: state.alert,
        alerts: state.alerts,
        setAlert,
        setAlerts,
        clearState,
        removeAlert,
      }}
    >
      {props.children}
    </AlertContext.Provider>
  );
};

export default AlertState;
