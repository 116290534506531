import React from "react";
import PropTypes from "prop-types";
import { Container } from "@mui/material";
import { Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const MainLayout = () => {
  const { loadUser } = useAuth();
  const styles = {
    wrapper: {
      backgroundColor: "#fff",
      display: "flex",
      minHeight: "100vh",
      overflow: "hidden",
    },
  };

  React.useEffect(() => {
    if (localStorage.getItem("token")) loadUser();
  }, [localStorage]);

  return (
    <div style={styles.wrapper}>
      <Container maxWidth="xl">
        <Outlet />
      </Container>
    </div>
  );
};

MainLayout.propTypes = {
  children: PropTypes.node,
};

export default MainLayout;
